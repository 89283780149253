.centerwrapper {
    width: 90%;
    max-width: 500px;
    margin: 0px auto;
    padding-top: 120px;
    margin-bottom: 120px;
    color: #fff;
}

input {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 8px;
    font-family: inherit;
    font-size: 16px;
    outline: none;
    border: none;
    margin-bottom: 16px;
}

textarea {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 8px;
    font-family: inherit;
    font-size: 16px;
    outline: none;
    border: none;
    margin-bottom: 16px;
    resize: vertical;
}

.colorpicker {
    width: 28px;
    height: 28px;
    border-radius: 40px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.colorpicker img {
    width: 20px;
    height: 20px;
    opacity: 0.7;
    transition: all 0.2s;
}

.colorpicker:hover img {
    opacity: 1;
}

.deleticoncreate {
    width: 20px;
    height: 20px;
    opacity: 0.5;
    cursor: pointer;
    transition: opacity 0.2s;
}

.deleticoncreate:hover {
    opacity: 1;
}

.button-s {
    display: inline-block;
    border: 1px solid #fff4;
    border-radius: 100px;
    padding: 8px 16px;
    transition: border 0.2s;
    cursor: pointer;
}

.button-s:hover {
    border: 1px solid #fff6;
}

.button-m {
    display: block;
    border: 1px dashed #fff3;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    margin-top: 40px;
    cursor: pointer;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    transition: border 0.2s;
}

.button-m:hover {
    border: 1px dashed #fff6;
}

.button-l {
    color: #fff;
    background-color: #000;
    display: block;
    padding: 24px;
    border-radius: 8px;
    text-align: center;
    margin-top: 40px;
    cursor: pointer;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1px;
    transition: transform 0.2s;
}

.button-l:hover {
    transform: scale(1.05);
}


.inputstyle {
    border: 1px solid #ccc;
    border-radius: 8px;
}

.editmachinemodalcontent label {
    display: block;
    color: #555;
}

.editmachinemodalcontent .inputinfo {
    color: #888;
    font-size: 14px;
    margin: 0px auto;
}

.editmachinemodalcontent .inputinfo b { 
    color: #000;
}

.inputcountercont {
    display: flex;
    justify-content: center;
    align-items: center;
}

.inputcountercont input {
    font-size: 40px;
    width: 160px;
    text-align: center;
    margin-bottom: 0px;
}

.inputcountercont span {
    background-color: #eee;
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    font-size: 24px;
    cursor: pointer;
    user-select: none;
    transition: background-color 0.2s;
}

.inputcountercont span:hover {
    background-color: #ddd;
}

hr {
    margin-bottom: 32px;
    margin-top: 32px;
    border: 1px solid #eee;
}

.emojiselect {
    font-size: 60px;
    cursor: pointer;
    user-select: none;
}

.emojilist {
    height: 240px;
    overflow-y: scroll;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-top: 12px;
}

.emojilist span {
    cursor: pointer;
    font-size: 32px;
    margin: 12px;
    width: 32px;
    display: inline-block;
}

.presetcont {
    text-align: center;
    margin-bottom: 24px;
}

.presetcont span {
    display: inline-block;
    border: 1px solid #fff3;
    border-radius: 80px;
    padding: 4px 12px;
    margin-left: 8px;
    margin-bottom: 8px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.presetcont span:hover {
    background-color: #fff1;
}