.failurebutton {
display: flex;
justify-content: center;
margin-right: 12px;
cursor: pointer;
}

.failurebutton-active {
    background-color: #fff;
    color: #000;
    border-radius: 20px;
    padding: 8px 12px;
}

.failurebutton-active img {
    opacity: 1!important;
    margin-left: 2px;
}

.failurebutton img {
    width: 20px;
    height: 20px;
    opacity: 0.5;
    transition: opacity 0.2s;
}

.failurebutton img:hover {
    opacity: 1;
}

.linebreak {
    border: 1px solid #fff3;
}

.inputcountercont-alt {
    display: flex;
    justify-content: center;
    align-items: center;
}

.inputcountercont-alt input {
    font-size: 40px;
    width: 160px;
    text-align: center;
    margin-bottom: 0px;
    color: #fff;
    background-color: transparent;
}

.inputcountercont-alt span {
    background-color: #fff2;
    color: #fff;
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    font-size: 24px;
    cursor: pointer;
    user-select: none;
    transition: background-color 0.2s;
}

.inputcountercont-alt span:hover {
    background-color: #fff3;
}

.headlinewithicon {
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.headlinewithicon img {
    width: 32px;
    margin-right: 8px;
}

.inputinfo-alt {
    color: #fffa;
    font-size: 14px;
    margin: 0px auto;
    text-align: center;
}

.inputinfo-alt b {
    color: #fff;
}