body {
    background-color: #3F4A55;
    overflow-x: hidden;
}

.app {
    overflow-x: scroll;
}

.titleblock {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 32px;
    left: 80px;
    cursor: pointer;
}

.gametitle {
    color: #fff;
    font-size: 24px;
}

.gamesubtitle {
    color: #fff;
    font-size: 14px;
    opacity: 0.6;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-top: 4px;
}

.gamesubtitle2 {
    color: #fff;
    font-size: 14px;
    opacity: 0.6;
    margin-top: 2px;
    transition: opacity 0.2s;
}

.titleblock:hover .gamesubtitle2 {
    opacity: 1;
}

.medal {
    width: 56px;
    height: 56px;
    border-radius: 40px;
    background-color: gold;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-weight: bold;
    color: #fff;
    line-height: 1;
    box-shadow: 0px 3px 0px #0005;
    position: relative;
    overflow: hidden;
}

.medal:after {
	content:'';
  top:0;
	transform:translateX(100%);
	width:56px;
	height:56px;
	position: absolute;
	z-index:1;
	animation: slide 2s infinite;
	 
 
	background: linear-gradient(to right,#fff0 0%,#fff8 50%,#fff0 100%); /* W3C */
}

/* animation */

@keyframes slide {
	0% {transform:translateX(-100%);}
    50% {transform:translateX(100%);}
	100% {transform:translateX(100%);}
}

.medal-gold {
    background-color: goldenrod;
    font-size: 28px;
    background: rgb(169,132,3);
    background: linear-gradient(45deg, rgba(169,132,3,1) 0%, rgba(241,202,103,1) 100%);
}

.medal-silver {
    background-color: silver;
    font-size: 28px;
    background: rgb(136,136,136);
    background: linear-gradient(45deg, rgba(136,136,136,1) 0%, rgba(207,207,207,1) 100%);
}

.medal-bronze {
    background-color: #985619;
    font-size: 28px;
    background: rgb(135,77,20);
    background: linear-gradient(45deg, rgba(135,77,20,1) 0%, rgba(205,127,50,1) 100%);
}

.medal-black {
    background-color: #0003;
}

.medal-black::after {
    display: none;
}

.medal-unknown {
    background-color: #0003;
    font-size: 28px;
}

.teamcontainer {
    display: flex;
    position: absolute;
    right: 32px;
    top: 32px;
    align-items: center;
}

.teamdotcont {
    display: flex;
    flex-direction: row;
    /*margin-right: 12px;*/
    align-items: center;
    color: #fff;
}

.teamdot {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    margin-left: -8px;
    line-height: 1;
    text-transform: uppercase;
    box-shadow: 0px 2px 4px #0004;
    position: relative;
    cursor: default;
}

.teamdot .tooltip {
    display: inline-block;
    background-color: #0008;
    border-radius: 4px;
    padding: 4px 8px;
    font-size: 12px;
    text-transform: none;
    position: absolute;
    max-width: 160px;
    text-align: center;
    top: -28px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    opacity: 0;
}

.teamdot:hover .tooltip { 
    opacity: 1;
}

.plusbutton {
    background-color: #fff;
    color: #000;
    height: 56px;
    font-size: 32px;
    padding: 0px 24px;
    border: none;
    outline: none;
    border-radius: 80px;
    box-shadow: 0px 4px 8px #0004;
    display: flex;
    align-items: center;
    font-size: 20px;
    position: absolute;
    bottom: 32px;
    left: 50%;
    transform: translate(-50%, 0);
    transition: all 0.2s;
}

.plusbutton img {
    width: 20px;
    height: 20px;
    margin-right: 12px;
}

.plusbutton:hover {
    transform: translate(-50%, 0) scale(1.05);
    box-shadow: 0px 12px 16px #0004;
}

.cancelbutton {
    background-color: transparent;
    color: #fff;
    height: 56px;
    padding: 0px 24px;
    border: 2px solid #fff6;
    outline: none;
    border-radius: 80px;
    display: flex;
    align-items: center;
    font-size: 20px;
    position: absolute;
    bottom: 32px;
    left: 50%;
    transform: translate(-50%, 0);
    transition: all 0.2s;
}

.cancelbutton:hover {
    background-color: #fff1;
}

.cancelbutton img {
    width: 14px;
    height: 14px;
    margin-right:12px;
}

.donebutton {
    background-color: #fff;
    color: #000;
    height: 56px;
    padding: 0px 24px;
    font-size: 20px;
    border: none;
    outline: none;
    border-radius: 80px;
    box-shadow: 0px 4px 8px #0004;
    position: absolute;
    right: 32px;
    bottom: 32px;
}

.costcont {
    position: absolute;
    bottom: 32px;
    left: 32px;
    display: flex;
    flex-direction: column;
    color: #fff;
}

.finishbuttonbox {
    position: absolute;
    bottom: 32px;
    right: 32px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    color: #fff;
    cursor: pointer;
}

.playground {
    display: flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
    padding: 0px 120px;
    box-sizing: border-box;
    margin: 0px auto;
    width: fit-content;
}

@keyframes machineappear {
    0% {
      transform: scale(0.5);
      opacity: 0;
    }
    70% {
      transform: scale(1.1);
      opacity: 1;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
  }

.machine {
    position: relative;
    margin: 0px 12px;
    cursor: pointer;
    transition: all 0.2s;
    display: flex;
    justify-content: center;
    align-items: center;
    /*animation: 0.4s machineappear;*/
}

.machine:hover {
    transform: scale(1.05);
}


.machine .deleteicon {
    background-color: #e74444;
    color: #fff;
    width: 32px;
    height: 32px;
    border-radius: 40px;
    display: none;
    justify-content: center;
    align-items: center;
    position: absolute;
    display: flex;
    top: -8px;
    right: -8px;
    z-index: 5;
    box-shadow: 0px 2px 8px #0004;
    opacity: 0;
    transition: all 0.2s;
    transform: translateY(4px);
}

.machine .deleteicon:hover {
    background-color: #b62d2d;
}

.machine:hover .deleteicon {
    transform: translateY(0px);
    opacity: 1;
}

.deleteicon img {
    width: 20px;
}

.machinetop {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 88px;
    height: 80px;
    border-radius: 12px;
    background-color: #fff;
    position: relative;
    z-index: 1;
}

.machinetop span {
    font-size: 48px;
    transform: rotateX(20deg);
}

.machinebottom {
    width: 88px;
    height: 80px;
    border-radius: 12px;
    background-color: #BBBBBB;
    box-shadow: 8px 6px 8px #0004;
    margin-top: -72px;
    z-index: 0;
    position: relative;
    transition: all 0.2s;
}

.machine:hover .machinebottom {
    box-shadow: 16px 12px 16px #0004;
}

.machinebottom::after {
    content: "";
    background: #0000;
    background: linear-gradient(90deg, #fff6 0%, #fff0 20%, #0000 80%, #0006 100%);
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 12px;
    left: 0;
}

.machinename {
    position: absolute;
    color: #fff;
    bottom: -32px;
    width: 160px;
    left: -36px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.machinevisual {
    position: relative;
    cursor: default;
}

.machinevisual .machinetop {
    border: 1px solid #ddd;
    box-sizing: border-box;
}

.machinevisual .machinebottom {
    box-shadow: 6px 6px 6px #0002;
}

.machinevisualbuilding {
    transform: rotate(12deg);
}

.machinevisualbuilding .machinebottom {
    box-shadow: 16px 16px 16px #0004;
}

.arrow1 {
    width: 50px;
}

.arrow2before {
    width: 104px;
}

.arrow2after {
    width: 90px;
}

.arrow3before {
    width: 104px;
}

.arrow3after {
    width: 90px;
}

.qualitygate {
    width: 110px;
}

.simulatetop {
    background-color: #71CE62;
    width: 72px;
    height: 64px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 24px;
    position: absolute;
    top: 0;
    z-index: 4;
    transition: background-color 0.2s;
}

.simulatebottom {
    position: absolute;
    z-index: 2;
    left: 8px;
    bottom: 16px;
    background-color: #429F32;
    width: 72px;
    height: 130px;
    max-height: 264px;
    border-radius: 8px;
    transition: all 0.2s;
}

.simulatebottom::after {
    content: "";
    background: #0000;
    background: linear-gradient(90deg, #fff3 0%, #fff0 20%, #0000 80%, #0003 100%);
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 8px;
}

/* Rounded columns */

.simulatetop-round {
    background-color: #fff;
    width: 64px;
    height: 64px;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #444;
    font-size: 24px;
    position: absolute;
    top: 0;
    z-index: 4;
}

.simulatebottom-round {
    position: absolute;
    z-index: 2;
    left: 8px;
    bottom: 16px;
    background-color: #ddd;
    width: 64px;
    height: 130px;
    max-height: 264px;
    border-radius: 40px;
    transition: all 0.2s;
}

.simulatebottom-round::after {
    content: "";
    background: #0000;
    background: linear-gradient(90deg, #fff8 0%, #fff0 48%, #0000 52%, #0003 100%);
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: 40px;
}

.item_startend_front {
    border-radius: 80px;
    width: 80px;
    height: 80px;
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.item_startend_front img {
    width: 42px;
    height: 40px;
}

.item_startend_bottom {
    border-radius: 80px;
    width: 80px;
    height: 80px;
    box-shadow: 8px 6px 8px #0004;
    margin-top: -72px;
    z-index: 0;
    position: relative;
}

.colorstart_front {
    background-color: #789DFC;
}

.colorstart_bottom {
    background-color: #4E77DF;
}

.colorend_front {
    background-color: #71CE62;
}

.colorend_bottom {
    background-color: #57A74A;
}

.item_startend {
    position: relative;
    margin: 0px 8px;
}

.inventory {
    height: 100vh;
    width: 300px;
    background-color: #fff;
    z-index: 100;
    position: fixed;
    top: 0;
    left: 0;
    padding: 20px;
}

.gatecontainer {
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
}

.gatecontainer1 {
    height: 90px;
    justify-content: center;
}

.gatecontainer2 {
    height: 260px;
    justify-content: space-between;
}

.gatecontainer3 {
    height: 420px;
    justify-content: space-between;
}

.worker {
    height: 32px;
    position: absolute;
    top: -32px;
    left: 27px;
}

.modalwrapper {
    position: fixed;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
}

.modalbg {
    background-color: #000a;
    width: 100vw;
    height: 100vh;
}

@keyframes modalappear {
    0% {
      transform: translateY(100px);
      opacity: 0;
    }
    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }

.modal {
    position:absolute;
    text-align: center;
    background-color: #fff;
    width: 90%;
    max-width: 500px;
    height: auto;
    padding: 32px;
    border-radius: 12px;
    justify-content: center;
    box-sizing: border-box;
    animation: 0.3s modalappear;
}

.twocolumngrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 16px;
    margin-top: 24px;
    padding-bottom: 32px;
}

.modalicon {
    position: absolute;
    top: -40px;
    /*font-size: 42px;
    line-height: 1;
    margin: 0px;
    background-color: #fff;
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 80px;
    box-shadow: 0px 4px 8px #0002;*/
}

.modalcontent {
    width: 100%;
    margin-top: 40px;
}

.modalheadline {
    font-size: 24px;
    margin: 0px auto;
    text-align: center;
}

.workergridcont {
    border-top: 2px solid #eee;
    padding-top: 32px;
}

.workergrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.reparationtimebadge {
    background-color: #ebf8e9;
    display: inline-block;
    margin-bottom: 28px;
    padding: 6px 12px;
    border: 1px solid #29811c33;
    border-radius: 24px;
    font-size: 14px;
    color: #29811c;
}

.workergriditem {
    border-radius: 8px;
    display: flex;
    justify-content: center;
    padding: 16px 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all 0.2s;
    font-size: 14px;
    cursor: pointer;
    border: 2px solid #fff;
}

.workergriditem:hover {
    background-color: #F0F4FF;
}

.workergriditem img {
    width: 56px;
    margin-bottom: 8px;
}

.greentext {
    color: #57A74A;
}

.notavailablebadge {
    font-size: 12px;
    background-color: #888;
    color: white;
    padding: 4px 8px;
    border-radius: 24px;
}

.workergriditemactive {
    background-color: #F0F4FF;
    border: 2px solid #BCCCF6;
    position: relative;
}

.workergriditemactive::after {
    content: "";
    height: 24px;
    width: 24px;
    background-image: url("img/checkmark.png");
    position: absolute;
    top: -8px;
    right: -8px;
    background-size: cover;
}

.blackbutton {
    background-color: #000;
    color: #fff;
    text-align: center;
    padding: 20px;
    border-radius: 8px;
    cursor: pointer;
    margin-top: 32px;
    transition: all 0.2s;
}

.blackbutton:hover {
    background-color: #222;
}

.finishsimulatebutton {
    background-color: #57A74A;
    color: #fff;
    font-size: 20px;
    text-align: center;
    padding: 20px;
    border-radius: 8px;
    cursor: pointer;
    margin-top: 8px;
    transition: all 0.2s;
    animation: buttonpulsate 1.5s infinite;
}

.finishsimulatebuttoninactive {
    background-color: #aaa;
    color: #fff;
    font-size: 20px;
    text-align: center;
    padding: 20px;
    border-radius: 8px;
    cursor: pointer;
    margin-top: 8px;
}

@keyframes buttonpulsate {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.05);
    }
    100% {
        transform: scale(1);
    } 
}

.finishsimulatebutton:hover {
    background-color: #71CE62;
}

.modalinventory {
    display: block;
    max-height: 90vh;
    overflow-y: scroll;
    max-width: 560px;
}

.inventoryitem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 12px 0px;
}

.inventoryitemleft {
    display: flex;
    align-items: center;
}

.inventoryitemlefttext {
    text-align: left;
    margin-left: 20px;
}

.inventoryitemtitle {
    margin: 0px;
    font-size: 20px;
}

.inventoryiteminfo {
    margin: 0px;
    font-size: 14px;
    color: #666;
    max-width: 160px;
}

.inventoryitembadge {
    margin: 0px;
    font-size: 11px;
    font-weight: bold;
    color: #333;
    background-color: #eee;
    border-radius: 8px;
    display: inline-block;
    padding: 4px 8px;
    margin: 8px 8px 0px 0px;
}

.inventorybutton {
    background-color: #000;
    color: #fff;
    border-radius: 8px;
    padding: 8px 16px;
    cursor: pointer;
    margin-left: 12px;
    transition: background-color 0.2s;
}

.inventorybutton:hover {
    background-color: #444;
}

.modalinventory .modalcontent {
    margin-top: 0px;
}

.buildplusbutton {
    width: 32px;
    height: 32px;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 32px;
    background-color: #fff;
    flex-shrink: 0;
    cursor: pointer;
    transition: all 0.3s;
    animation: plusbutton 1.5s infinite;
}

@keyframes plusbutton {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    } 
}

.buildplusbutton:hover {
    background-color: #ccc;
}

.buildplusbutton img {
    width: 16px;
}

.buildplusbuttoncontainer {
    position: absolute;
    bottom: -90px;
}


.failuremachine {
    background-color: #fff;
    color: #000;
    border-radius: 20px;
    padding: 4px 10px;
    position: absolute;
    z-index: 9;
    /*right: -8px;*/
    bottom: -8px;
    border: 2px solid #e1454588;
    line-height: 1;
    /*animation: plusbutton 1s infinite;*/
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
}

.failuremachine img {
    width: 20px;
    height: 20px;
    margin-right: 6px;
    animation: plusbutton 1s infinite;
}

.simulationcontrolscont {
    position: absolute;
    display: flex;
    background-color: #0003;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 22px;
    border-radius: 12px;
    position: absolute;
    bottom: 32px;
    left: 50%;
    transform: translate(-50%, 0);
}
.simulationcontrol {
 background-color: #0000;
 margin: 4px;
 width: 56px;
 height: 56px;
 display: flex;
 justify-content: center;
 align-items: center;
 border-radius: 8px;
 cursor: pointer;
 transition: all 0.2s;
 user-select: none;
}

.simulationcontrol:hover {
    background-color: #0003;
}

.simulationcontrol img {
    width: 48px;
}

.resultsinfo-table div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 4px;
    margin-bottom: 4px;
    text-align: left;
}

.bigtext {
    font-size: 32px;
}

.resultsinfo-table hr {
    margin-top: 16px;
    margin-bottom: 16px;
}

.tabbuttongroup {
    display: flex;
    justify-content: center;
    margin-top: 32px;
    margin-bottom: -12px;
}

.tabbuttongroup .tabbutton {
    padding: 8px 16px;
    border-radius: 20px;
    background-color: #fff;
    color: #888;
    margin: 2px;
    cursor: pointer;
}

.tabbuttongroup .active {
    background-color: #eee;
    color: #000
}

.modalresults {
    display: block;
    max-height: 90vh;
}

.modalresults .modalcontent {
   overflow-y: scroll;
}

.maxvalueitem {
    width: 40px;
    height: 40px;
    background-color: #aaa;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
}

/* GAME Playground */

.choosescenariobutton {
    border: 1px solid #ddd;
    border-radius: 12px;
    padding: 20px;
    cursor: pointer;
    transition: background-color 0.2s, border 0.2s;
}

.choosescenariobutton:hover {
    background-color: #f5f5f5;
    border: 1px solid #ccc;
}

.choosedifficultybutton {
    border: 1px solid #ddd;
    border-radius: 12px;
    padding: 12px;
    cursor: pointer;
    transition: background-color 0.2s, border 0.2s;
}

.choosedifficultybutton:hover {
    background-color: #f5f5f5;
    border: 1px solid #ccc;
}

.choosedifficultybuttonactive {
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    box-shadow: 0px 2px 0px #ccc;
}

.choosedifficultybuttonactive.choosedifficultybutton-Easy {
    border: 1px solid #a8e777;
    background-color: #ebfcd4;
    box-shadow: 0px 2px 0px #a8e777;
}

.choosedifficultybuttonactive.choosedifficultybutton-Medium {
    border: 1px solid #FBD794;
    background-color: #FDF5DB;
    box-shadow: 0px 2px 0px #FBD794;
}

.choosedifficultybuttonactive.choosedifficultybutton-Hard {
    border: 1px solid #F7A28C;
    background-color: #FCE6D9;
    box-shadow: 0px 2px 0px #F7A28C;
}

/* Quality gate deleteicon */

.qualitygatecontainer {
    position: relative;
}

.qualitygatecontainer .deleteicon {
    background-color: #e74444;
    color: #fff;
    width: 32px;
    height: 32px;
    border-radius: 40px;
    display: none;
    justify-content: center;
    align-items: center;
    position: absolute;
    display: flex;
    top: 70px;
    left: 35px;
    z-index: 5;
    box-shadow: 0px 2px 8px #0004;
    opacity: 0;
    transition: all 0.2s;
    transform: translateY(4px);
}

.qualitygatecontainer .deleteicon:hover {
    background-color: #b62d2d;
}

.qualitygatecontainer:hover .deleteicon {
    transform: translateY(0px);
    opacity: 1;
}

.bestresult {
    justify-content: flex-end!important;
    font-size: 12px;
}

.casetipbox {
    background-color: #FFC10722;
    border: 2px solid #FFC10788;
    border-radius: 8px;
    padding: 16px;
    font-size: 14px;
    text-align: left;
    color: #6e5508;
    display: flex;
    margin-top: 40px;
}

.casetipbox img {
    width: 32px;
    height: 32px;
    margin-right: 12px;
}

.intentiontextarea {
    border: 2px solid #ddd;
}

.gamedescriptiontext {
    font-size: 14px;
    text-align: left;
    line-height: 150%;
}

.videoframe {
    width: 100%;
    margin-top: 16px;
    margin-bottom: 16px;
}

.learningslist {
    text-align: left;
    margin-bottom: 48px;
}

.learningitem {
    border-bottom: 2px solid #eee;
    padding: 20px 0px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    animation-name: learningitem;
  animation-duration: 400ms;
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
}

.learningitem:nth-child(1) {
    animation-delay: 100ms;
  }
  
.learningitem:nth-child(2) {
    animation-delay: 200ms;
  }
  
  .learningitem:nth-child(3) {
    animation-delay: 300ms;
  }

  .learningitem:nth-child(4) {
    animation-delay: 400ms;
  }

  .learningitem:nth-child(5) {
    animation-delay: 500ms;
  }

.learningitem p {
    margin: 0px auto;
}

.learningitem-emoji {
    font-size: 32px;
    margin-right: 16px;
}

@keyframes learningitem {
    0% {
      opacity: 0;
      transform: scale(0.6) translateY(-8px);
    }
    
    100% {
      opacity: 1;
    }
  }
