.gamelistitem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0px;
    border-top: 1px solid #fff2;
    cursor: pointer;
    font-size: 20px;
}

.gamelistitem img {
    width: 24px;
    transition: transform 0.2s;
}

.gamelistitem:hover img {
    transform: translateX(4px);
}

.linkwithimage {
    font-size: 18px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.6;
    transition: opacity 0.2s;
    cursor: pointer;
}

.linkwithimage:hover {
    opacity: 1;
}

.linkwithimage img {
    width: 20px;
    margin-left: 8px;
}

.playgroundbtn {
    width: 100%;
    padding: 60px 20px;
    background-color: #fff;
    box-sizing: border-box;
    color: #000;
    text-align: center;
    font-size: 24px;
    border-radius: 12px;
    cursor: pointer;
    transition: all 0.2s;
}

.playgroundbtn:hover {
    transform: scale(1.02);
    background-color: rgb(238, 254, 212);
}

.codeinput {
    text-align: center;
    font-size: 28px;
    background-color: transparent;
    border: 2px solid #fff4;
    color: #fff;
}

.codeinput::placeholder {
    color: #fff4;
}

footer {
    padding: 24px;
    text-align: center;
    color: #fff;
}

footer a {
    font-size: 14px;
    margin: 8px;
    opacity: 0.5;
    transition: opacity 0.2s;
}

footer a:hover {
    opacity: 1;
}