.rounditem {
    display: flex;
    background-color: #0002;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    border-radius: 12px;
    margin-bottom: 12px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.rounditem:hover {
    background-color: #0003;
}

.rounditem-right {
    display: flex;
    align-items: center;
}

.rounditem-text {
    display: flex;
    flex-direction: column;
    margin-left: 16px;
}

.rounditem-joinbutton {
    padding: 8px 20px;
    border-radius: 20px;
    background-color: #fff;
    color: #000;
}

.rounditem-arrow {
    width: 24px;
}

.loginswitchtext {
    text-align: center;
    margin-top: 40px;
}

.loginswitchtext a {
    color: inherit;
    text-decoration: underline;
}

.accesscodecont {
    text-align: center;
    margin-bottom: 40px;
}

.accesscodecont p {
    font-size: 30px;
    margin: 0px;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.leaderboardcard {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0px;
    border-bottom: 1px solid #fff4;
    padding: 24px 16px;
}

.leaderboardcard-left {
    display: flex;
    align-items: center;
}

.leaderboardcard-text {
    margin-left: 16px;
}

.leaderboardcard-text p {
    margin: 0px;
}

.leaderboardcard-text-name {
    font-size: 18px;
    margin-bottom: 4px!important;
    max-width: 280px;
}

.leaderboardcard-text-subline {
    font-size: 14px;
    opacity: 0.8;
}

.leaderboardcard-unitprice {
    font-size: 20px;
    color: #71CE62;
}

.leaderboardcard-unitprice-red {
    color: #ff8b82;
}

.leaderboardcard-highlighted {
    background-color: #fff1;
}

.rankingnotdonecard {
    padding: 24px 0px;
    border-bottom: 1px solid #fff4;
}

.rankingnotdonecard-highlighted {
    background-color: #fff1;
}

.gameoverviewwrapper {
    width: 90%;
    max-width: 1400px;
    margin: 0px auto;
    margin-bottom: 80px;
}

.gameoverviewmenubar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 48px 0px;
    color: #fff;
}

.gameoverviewmenubar h1 {
    margin: 0px auto;
}

.gameoverviewmenubar p {
    margin: 0px auto;
}

.chapterwrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 20px;
    row-gap: 20px;
}

.chaptercard {
    background-color: #0002;
    padding: 12px;
    box-sizing: border-box;
    height: 280px;
    border-radius: 12px;
    position: relative;
    color: #fff;
    background-size: cover;
    background-position: center;
    overflow: hidden;
    cursor: pointer;
    transition: transform 0.2s, box-shadow 0.2s;
}

.chaptercard h2 {
    margin: 0px auto;
    font-size: 22px;
    margin-top: 4px;
}

.chaptercard p {
    margin: 0px auto;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1px;
    opacity: 0.6;
}

.chaptercardtext {
    position: absolute;
    bottom: 24px;
    left: 24px;
}

.chaptercardshadow {
    background-color: green;
    width: 100%;
    height: 160px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: rgb(0,0,0);
    background: linear-gradient(0deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0) 100%);
}

.cardlockicon {
    width: 48px;
    height: 48px;
    z-index: 2;
    background-color: #0008;
    padding: 20px;
    border-radius: 100px;
    transition: transform 0.2s;
    backdrop-filter: blur(2px);
}

.chaptercardlocked {
    display: flex;
    justify-content: center;
    align-items: center;
    filter: grayscale(1);
    cursor: auto;
}

.chaptercardlocked .chaptercardshadow {
    height: 100%;
    background: #0008;
    backdrop-filter: blur(8px);
}

.chaptercardlocked:hover .cardlockicon {
    transform: scale(1.1);
}

.playnowbtn {
    display: inline-block;
    background-color: #fff;
    color: #000;
    z-index: 5;
    position: absolute;
    top: 16px;
    right: 16px;
    padding: 12px 20px;
    border-radius: 40px;
    box-shadow: 0px 4px 12px #0004;
    /*transition: transform 0.2s, box-shadow 0.2s;*/
    animation: buttonpulse 1.6s infinite;
    font-weight: bold;
}

@keyframes buttonpulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}

.chaptercard:hover {
    transform: scale(1.02);
    box-shadow: 0px 12px 32px #0004;
}

/*
.chaptercard:hover .playnowbtn {
    transform: scale(1.05);
    box-shadow: 0px 8px 20px #0008;
}
*/

.medalcontainer {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff1;
    padding: 14px 20px;
    border-radius: 16px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.medalcontainer:hover {
    background-color: #fff2;
}

.doneicon {
    position: absolute;
    right: 12px;
    top: 12px;
    background-color: #000a;
    display: flex;
    width: 48px;
    height: 48px;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    backdrop-filter: blur(2px);
}

.doneicon img {
    width: 24px;
    height: 24px;
    margin: 0px auto;
}

.readcasebtn {
    font-size: 16px;
    opacity: 0.6;
    cursor: pointer;
    transition: opacity 0.2s;
    padding: 8px 0px;
}

.readcasebtn:hover {
    opacity: 1;
}

.chapterselectioncontainer {
    text-align: center;
    margin: 32px 0px;
}

.chapterselectionitem {
    background-color: #0002;
    padding: 8px 16px;
    border-radius: 20px;
    display: inline-block;
    cursor: pointer;
    font-size: 14px;
    margin: 6px;
}

.chapterselectionitem-active {
    background-color: #fff;
    color: #000;
    box-shadow: 0px 4px 8px #0002;
}

.timeline {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    border-bottom: 1px solid #fff4;
}

.timeline-s .timelineitem {
    width: 20px;
}

.timeline-xs .timelineitem {
    width: 8px;
    margin: 0px 3px;
}

.timelineitem {
    background-color: #71CE62;
    width: 32px;
    margin: 0px 4px;
    border-radius: 4px 4px 0px 0px;
    transition: background-color 0.2s;
}

.timelineitem:hover {
    background-color: #8cdf7f;
}

.timelinecontainer {
    position: relative;
}

.timelinebubble {
    background-color: #fff;
    color: #000;
    padding: 12px;
    line-height: 1.2;
    border-radius: 12px;
    position: absolute;
    font-size: 12px;
    top: 0;
    left: 0;
    transform: translateX(calc(-50% + 20px)) translateY(calc(-100% - 10px));
    white-space: nowrap;
    visibility: hidden;
    opacity: 0;
    z-index: 5;
}

.timelinebubble p {
    margin: 0px auto;
}

.timelinecontainer:hover .timelinebubble {
    opacity: 1;
    visibility: visible;
}

