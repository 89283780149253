@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@500;700&display=swap');

body {
  margin: 0;
  font-family: 'IBM Plex Sans' ,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
  font-family: 'IBM Plex Sans', sans-serif;
  cursor: pointer;
  outline: none;
  border: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.gradientbg {
  background: #3F4A55;
  background: radial-gradient(closest-side, #576471 0%, #3F4A55 100%);
  width: 100%;
  width: 100vw;
  height: 100%;
  height: 100vh;
  position: fixed;
  z-index: -100;
}

a {
  text-decoration: none;
  color: inherit;
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loadingcontainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 160px;
}

.loadingcontainer img {
  width: 40px;
  animation: loading 1s linear infinite;
}

.emptystate-text {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff1;
  border-radius: 8px;
  padding: 32px;
}

.backicon {
  width: 32px;
  position: absolute;
  top: 32px;
  left: 32px;
  cursor: pointer;
}

.modalcancel {
  position: absolute;
  width: 40px;
  height: 40px;
  background-color: #fff;
  border-radius: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 4px #0003;
  top: -16px;
  right: -16px;
  cursor: pointer;
}

.modalcancel img {
  width: 20px;
}